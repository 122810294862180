import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldBrimley: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Brimley?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_brimley.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Brimley?</h1>
          <h2>
            Not sure if pulling Brimley is the right move? No worries! Check our
            review and guide for him!
          </h2>
          <p>
            Last updated: <strong>10/Feb/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="brimley"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          Realistically, you’ll get Brimley while pulling for Flutterpage.
          People who don’t want Flutterpage should not pull on her banner just
          for Brimley because 1) pulling for 5-stars in this game sucks, and 2)
          Brimley works better with Flutterpage anyway. If you get them both,
          you can certainly give Brimley a shot:
        </p>
        <ul>
          <li>He enables any character to do follow-up attacks.</li>
          <li>
            He offers pretty good support for FUA characters by granting ATK%
            buffs and extra actions.
          </li>
          <li>He cleanses debuffs.</li>
          <li>He reduces Reality DEF with his ultimate.</li>
        </ul>
        <SectionHeader title="Review" />
        <p>
          <i>
            Note: This guide uses his unofficial translation, as the official
            localization was not available at the time of writing.
          </i>
        </p>
        <p>
          Brimley’s kit revolves around his unique buff, [Let’s Ride]. Here’s a
          quick rundown on how it works:
        </p>
        <ul>
          <li>
            When other allies perform extra actions or Brimley casts his attack
            skill [Keep Movin’!], Brimley gains [Equestrianism].
          </li>
          <li>
            If he has 3 or more [Equestrianism] when he casts [Keep Movin’!], he
            depletes all [Equestrianism] stacks and the ally with the [Let’s
            Ride] status casts their Skill 2 at incantation rank 1 as an extra
            action.
          </li>
          <li>
            When he casts his Ultimate [Let’s Go, Mate!], the ally with the
            [Let’s Ride] status casts their Skill 1 at incantation rank 1 as an
            extra action.
          </li>
        </ul>
        <p>
          His Insight I allows [Saddle Up!] (the buff card that applies [Let’s
          Ride]) to cleanse 2 random [Stats Down], [Neg Status], and [Control]
          from the target, much like how 6’s does. It’s single-target and [Let’s
          Ride] lasts 2 rounds, so you might still need to bring an AoE cleanser
          like Vila or 2.4’s Fatutu in status-heavy stages (like Gold in the
          Cave).
        </p>
        <p>
          As mentioned above, his Insight III gives him [Equestrianism] when
          allies perform extra actions. It also gives him +1 Moxie each time the
          ally under [Let’s Ride] performs 3 actions each round.
        </p>
        <p>
          His Skill 1 [Keep Movin’!] does OK damage but mostly exists to proc
          the extra action. Keep in mind that he will use all stacks of
          [Equestrianism], so plan to use his attack when his [Equestrianism] is
          at 2 stacks.
        </p>
        <p>
          His Skill 2 [Saddle Up!] applies [Let’s Ride] to one ally; at higher
          ranks it also grants [Equestrianism]. Note that only one [Let’s Ride]
          effect may exist at one time. In addition to granting extra actions,
          [Let’s Ride] also increases the target’s ATK by 5% of Brimley’s ATK.
        </p>
        <p>
          His Ultimate [Let’s Go, Mate!] deals decent Mental damage to all
          enemies and reduces Reality DEF by 20% for 3 turns. To trigger both
          the Moxie and [Equestrianism] Insight III passives, you’ll want your
          DPS under [Let’s Ride] to cast 2 incantations and 1 FUA per turn.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    A fun, unique kit that enables other characters to do extra
                    actions.
                  </li>
                  <li>Buffs ATK and reduces Reality DEF.</li>
                  <li>
                    Synergizes very well with his bannermate, Flutterpage.
                  </li>
                  <li>AP-positive even with 100% uptime.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Uses all [Equestrianism] when he attacks, so he can’t make
                    use of overcapping.
                  </li>
                  <li>
                    Mental DMG dealer who buffs Reality DMG; synergizes poorly
                    with Reality or Mental mass buffers.
                  </li>
                  <li>
                    Needs some planning to time Skill 1 & Skill 2 FUA well.
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>
          Brimley is complete at P0 and his portraits actually don’t offer much.
        </p>
        <p>
          P1, P2, and P4 only increase his damage. P3 is notable because it
          allows his buff to cleanse 1 more debuff per cast. P5 increases his
          ATK buff to 8% of his ATK. Out of all of these, I’d say P5 is the
          best. P3 is nice in case you can’t run a cleanser, but most of your
          healers (Tooth Fairy, Vila, and 2.4’s Fatutu) can cleanse anyway so
          the extra cleanse isn’t that important. On the other hand, P5 actually
          impacts his buffing capability, which is the main reason you’ll want
          to use him.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Brimley’s Insight III gives him great synergy with frequent FUA
          characters like Flutterpage and Lucy. Flutterpage is a perfect buff
          target because while she’s in her channel state, she casts a bunch of
          FUA every turn for no AP, allowing Brimley to gain Moxie and
          [Equestrianism] for free. Most importantly, Brimley allows a
          channeling Flutterpage to cast her Skill 2 for +AP each turn, which
          she usually cannot do without Tuning. She and Lucy are also Reality
          characters who can benefit from his Reality DEF shred. Lucy is more
          AP-hungry than Flutterpage and only gets consistent FUA in stages with
          lots of small mobs, but she makes for a great DPS for Brimley to buff.
        </p>
        <p>
          Lilya after her Euphoria buff is also a great consideration,
          especially since her Euphoria upgrade lets her perform another FUA if
          her Skill 2 crits. This gives her 2 more chances to gain [Enthusiasm]
          per Brimley-triggered-FUA (if both hits crit).
        </p>
        <p>
          Anjo Nala is another good candidate with lots of FUA, but she doesn’t
          benefit from Brimley’s Reality DEF shred. On the bright side, Brimley
          will make Anjo Nala cast her channel more often to cast [Angel’s
          Temptation] more frequently.
        </p>
        <p>
          37 is also a Mental DMG dealer but she does appreciate having as much
          FUA in her team as possible. Brimley’s ATK% buff also translates to
          more Genesis DMG, though you’d probably want to run it on Flutterpage
          in the same team as 37 in that case.
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="flutterpage"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lucy"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="lilya"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="anjo-nala"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="37"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <h5>Anti-Synergies:</h5>
        <p>
          Brimley notably does not work with J because his buff cleanses J’s
          [Burn].
        </p>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Brimley:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="her-second-life"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="long-night-talk"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="brave-new-world"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="his-bounden-duty"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="in-the-shadow"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          “Her Second Life” works well for Brimley because of its ATK% substat,
          which directly translates to his ATK buff. The extra healing is a
          decent upside but you should probably run a real sustain on the team.
        </p>

        <p>
          “Long Night Talk” is a great option for Brimley in long fights because
          it gives ATK% each time he buffs. At Amplification 5, it offers even
          more ATK% (12.5%) than ATK substat psychubes (10%). He doesn’t have a
          way to natively trigger the DMG Dealt buff, though.
        </p>
        <p>
          As always, “Brave New World” and “His Bounden Duty” are fine options
          especially if you already have them built.
        </p>
        <p>
          A hyper-specific but kinda expensive option is “In the Shadow,” which
          actually works really well with his kit. It grants 10% ATK over three
          buffs and gives him 12% DMG Reduction in total. The only caveat is
          that 5* psychube stats are never ideal.
        </p>
        <SectionHeader title="Resonance & Resonance Pattern" />
        <h5>Resonances</h5>
        <StaticImage
          src="../../../images/reverse/generic/brimley_r_1.webp"
          alt="Guides"
        />
        <h5>Resonance Pattern</h5>
        <p>
          <strong>Hyper - ATK (Recommended)</strong>
        </p>
        <p>
          <strong>Equanimity - Generalized</strong>
        </p>
        <p>
          Brimley’s buff scales off of ATK so Hyper is the best option here. If
          you want more survivability, try Delirament.
        </p>
        <SectionHeader title="Teams" />
        <p>
          A A few suggestions are listed below. 3-People teams are highlighted,
          with the 4th slot added at the side to provide a 4-P variation of the
          same team or even insights on possible variations of the 3-P team.
        </p>
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            I Only Play New Characters
            <span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="flutterpage"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="brimley"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="fatutu"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options:{' '}
                <strong>
                  37, Anjo Nala, Jiu Niangzi, Lilya (Euphoria), Lucy
                </strong>
              </li>
              <li>
                Flutterpage’s frequent FUAs make retrieving [Spirit Shells] a
                cinch, keeping the team alive.
              </li>
              <li>
                Fatutu’s FUAs and Brimley’s triggered FUAs help to keep up
                Flutterpage’s [Gust] stacks.
              </li>
              <li>
                Brimley allows Flutterpage to cast her Skill 2 for +1 AP even
                while she’s channeling.
              </li>
              <li>
                Fatutu can cleanse 1 debuff from all allies through her Skill 1
                and FUA, while Brimley can do more extensive cleansing through
                his single-target buff.
              </li>
              <li>
                This comp is pretty AP-positive, so feel free to throw in a more
                AP-hungry DPS as your main carry in the 4th slot.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            A Torrent of Small Tricks
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lilya"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="brimley"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Sub DPS</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>37, Anjo Nala, Kakania</strong>
              </li>
              <li>
                Brimley allows Lilya to FUA even more often. With [Aerial
                Maneuvers] buffed to also FUA, Brimley can let Lilya stack up
                [Enthusiasm] even faster for very little AP.
              </li>
              <li>
                As a Reality DPS, Lilya benefits from Brimley’s Reality DEF
                shred.
              </li>
              <li>
                Funnily enough, Brimley’s Mental DMG designation is a benefit
                here, since his presence lets Vila gain 1 extra Moxie when
                entering battle due to her Insight I.
              </li>
              <li>
                Brimley’s spot cleansing can help in debuff-heavy stages to tide
                the team over between Vila’s ultimates.
              </li>
              <li>
                With how many FUAs Lilya is putting out, 37 makes a great 4th
                slot as an AFK sub-DPS. Anjo Nala also works well as Lilya’s
                Moxie regeneration will help Anjo Nala ult more often. In high
                pressure scenarios, Kakania is a reliable pick who works well
                with Vila.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldBrimley;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Brimley? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Brimley is the right move? No worries! Check our review and guide for him!"
    game="reverse"
  />
);
